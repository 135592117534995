const styles = ({ palette }) => ({
  Wrapper: {
    padding: '1rem',
    position: 'relative',
    '&:hover': {
      '& > #article-box': {
        bottom: '1.9rem',
        color: palette.hover.tawny,
      },
    },
  },
  InfoBox: {
    position: 'absolute',
    left: '1.5rem',
    bottom: '1.5rem',
    transition: '0.3s',
    color: 'white',
    fontWeight: 600,
    fontSize: '2rem',
  },
  InfoBoxMobile: {
    fontSize: '1.2rem',
    bottom: '2rem',
  },
  Image: {
    borderRadius: '0.5rem',
    boxShadow: '0px 0px 9px 4px rgba(0,0,0,0.39)',
  },
});

export default styles;
