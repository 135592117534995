import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Link from 'gatsby-link';
import Img from 'gatsby-image';
import clsx from 'clsx';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import breakpoints from '../../utils/breakpoints';
import styles from './style';

const useStyles = makeStyles(styles);

const ArticleCard = ({ mainPicture, articleLink, articleTitle }) => {
  const classes = useStyles();
  const isMobile = useMediaQuery(breakpoints.sm);

  return (
    <Link to={`/article/${articleLink}`} style={{ textDecoration: 'none' }}>
      <div className={classes.Wrapper}>
        <Img fluid={mainPicture.fluid} alt='' className={classes.Image} />
        <div
          id='article-box'
          className={clsx(classes.InfoBox, {
            [classes.InfoBoxMobile]: isMobile,
          })}
        >
          {articleTitle}
        </div>
      </div>
    </Link>
  );
};

export default ArticleCard;
