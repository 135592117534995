import React from 'react';
import { Container, Fade, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import styles from './style';

import ArticleCard from '../../components/articleCard/ArticleCard';
import RenderDescription from '../../components/descriptionsRender/DescriptionRender';

const useStyles = makeStyles(styles);

const About = ({ articles, articlesTitle }) => {
  const classes = useStyles();
  return (
    <Container>
      <Fade in timeout={1500}>
        <div className={classes.Title}>
          <RenderDescription description={articlesTitle} />
        </div>
      </Fade>
      <div className={classes.Line} />
      <div className={classes.ArticlesBox}>
        <Grid container>
          {articles.map((article, index) => {
            return (
              <Grid
                item
                lg={4}
                md={6}
                sm={6}
                xs={12}
                key={`${article.articleTitle}-${index}`}
              >
                <Fade in timeout={1500} key={article.mainPicture.fluid.src}>
                  <ArticleCard
                    mainPicture={article.mainPicture}
                    articleLink={article.articleLink}
                    articleTitle={article.articleTitle}
                  />
                </Fade>
              </Grid>
            );
          })}
        </Grid>
      </div>
    </Container>
  );
};

export default About;
