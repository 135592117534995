import React from 'react';

import { Layout, SEO } from 'components';
import getArticlesData from '../queries/articles.module';
import Articles from '../containers/articles/Articles';

const ArticlesPage = () => {
  const {
    contentfulGeneralContentAtrticlesTitleRichTextNode,
    allContentfulArticles,
  } = getArticlesData();
  const { listOfArticles } = allContentfulArticles.nodes[0];

  const articlesTitle = contentfulGeneralContentAtrticlesTitleRichTextNode;

  return (
    <Layout>
      <SEO title='Funny Dogify articles' />
      <Articles articles={listOfArticles} articlesTitle={articlesTitle} />
    </Layout>
  );
};

export default ArticlesPage;
