import { useStaticQuery, graphql } from 'gatsby';

const getArticlesData = () => {
  const data = useStaticQuery(
    graphql`
      query {
        allContentfulArticles {
          nodes {
            listOfArticles {
              articleTitle
              articleLink
              mainPicture {
                fluid(maxWidth: 450, maxHeight: 350) {
                  ...GatsbyContentfulFluid
                }
              }
            }
          }
        }
        contentfulGeneralContentAtrticlesTitleRichTextNode {
          json
        }
      }
    `,
  );
  return data;
};

export default getArticlesData;
